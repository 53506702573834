import React, { Suspense, lazy } from "react";
import { Route, Switch } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { Row, Col, Spinner } from "react-bootstrap";
import Navigation from "./navigation/Navigation";

const Home = lazy(() => import("./pages/Home/index"));
const About = lazy(() => import("./pages/About/index"));
const Services = lazy(() => import("./pages/Services/index"));
const Testimonials = lazy(() => import("./pages/Testimonials/index"));
const Contact = lazy(() => import("./pages/Contact/index"));

const App = () => {
	return (
		<React.Fragment>
			<Navigation />
			<Suspense
				fallback={
					<div
						style={{
							height: "100vh",
							width: "100vw",
							textAlign: "center",
							marginTop: "50%",
						}}
					>
						<Spinner animation="grow" variant="info" />
					</div>
				}
			>
				<Switch>
					<Route exact path="/" component={Home} />
					<Route path="/about" component={About} />
					<Route path="/services" component={Services} />
					<Route path="/testimonials" component={Testimonials} />
					<Route path="/contact" component={Contact} />
				</Switch>
			</Suspense>
			<div className="footer">
				<Row>
					<Col>© 2022 STAT AIR INTERNATIONAL</Col>
				</Row>
			</div>
		</React.Fragment>
	);
};

export default App;
