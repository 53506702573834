import React, { useEffect, useState, useRef } from "react";
import {
	Nav,
	Navbar,
	Container,
	Button,
	Modal,
	Row,
} from "react-bootstrap";
import { NavLink } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

//assets
import logo from "../assets/Images/logo.png";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import CustomButton from "../assets/Buttons/CustomButton";
import US from "../assets/Images/united-states.png";

//styles
import "./Navigation.css";
import "../assets/Styles/Styles.css";

AOS.init();

const Navigation = () => {
	const [navBackground, setNavBackground] = useState(false);
	const [show, setShow] = useState(false);
	const [expanded, setExpanded] = useState(false);

	const navRef = useRef();
	navRef.current = navBackground;

	useEffect(() => {
		const handleScroll = () => {
			const show = window.scrollY > 50;
			if (navRef.current !== show) {
				setNavBackground(show);
			}
		};
		document.addEventListener("scroll", handleScroll);
		return () => {
			document.removeEventListener("scroll", handleScroll);
		};
	}, []);

	return (
		<div className="overallbackground">
			<Modal
				show={show}
				onHide={() => setShow(!show)}
				backdrop="static"
				size="sm"
			>
				<Modal.Header>
					<Modal.Title>Call Us</Modal.Title>
					<Button variant="light" onClick={() => setShow(!show)}>
						X
					</Button>
				</Modal.Header>
				<Modal.Body style={{ textAlign: "center" }}>
					<Row style={{ marginBottom: 20 }}>
						<CustomButton
							className="button-primary"
							flag={US}
							iconClassName="button-primary-icon"
							text="DOMESTIC +1-800-557-5911"
							onClick={() => window.location.assign("tel: +1-800-557-5911")}
						/>
					</Row>
					<Row>
						<CustomButton
							className="button-primary"
							icon={faGlobe}
							iconClassName="button-primary-icon"
							onClick={() => window.location.assign("tel: +1-619-799-5353")}
							text="INTERNATIONAL +1-619-799-5353"
						/>
					</Row>
				</Modal.Body>
			</Modal>
			<Navbar
                // collapseOnSelect={true}
				expand={false}
				fixed="top"
				style={{
					transition: ".5s ease",
					backgroundColor: expanded
						? "white"
						: navBackground
						? "white"
						: "transparent",
					color: "black",
					height: expanded ? "100vh" : navBackground ? 70 : 100,
					boxShadow: navBackground ? "1px 1px 1px lightgray" : "none",
				}}
			>
				<Container>
					<Navbar.Brand href="/" style={{ marginRight: "auto" }}>
						{expanded ? null : navBackground ? (
							<img src={logo} alt="logo" width={"200"} />
						) : null}
					</Navbar.Brand>
					{expanded ? null : (
						<Button
							variant="navbar-light"
							style={{ padding: 10, marginRight: 30 }}
							className={navBackground ? "callButtonB" : "callButton"}
							onClick={() => setShow(!show)}
						>
							CALL US NOW
						</Button>
					)}
					<Navbar.Toggle
						className={
							expanded
								? 'noBorder'
								: navBackground
								? "custom-togglerB"
								: "custom-toggler"
						}
						onClick={() => setExpanded(!expanded)}
					>
						{expanded ? (
							<Button variant="light">
								X
							</Button>
						) : null}
					</Navbar.Toggle>
					<Navbar.Collapse style={expanded ? {display: 'block'} : {display: 'none'}}>
						<Nav className="ml-auto">
							<NavLink
								exact
								activeClassName="active"
								className="nav-item"
								to="/"
                                onClick={() => setExpanded(!expanded)}
							>
								HOME
							</NavLink>
							<NavLink
								exact
								activeClassName="active"
								className="nav-item"
								to="/about"
                                onClick={() => setExpanded(!expanded)}
							>
								ABOUT
							</NavLink>
							<NavLink
								exact
								activeClassName="active"
								className="nav-item"
								to="/services"
                                onClick={() => setExpanded(!expanded)}
							>
								SERVICES
							</NavLink>
							<NavLink
								exact
								activeClassName="active"
								className="nav-item"
								to="/testimonials"
                                onClick={() => setExpanded(!expanded)}
							>
								TESTIMONIALS
							</NavLink>
							<NavLink
								exact
								activeClassName="active"
								className="nav-item"
								to="/contact"
                                onClick={() => setExpanded(!expanded)}
							>
								CONTACT
							</NavLink>
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
		</div>
	);
};

export default Navigation;
