import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../Styles/Styles.css";
import { Button, Image } from "react-bootstrap";

const CustomButton = (props) => {

	return (
		<div style={props.style}>
			<Button className={props.className} onClick={props.onClick}>
				{props.icon ? (
					<FontAwesomeIcon className={props.iconClassName} icon={props.icon} />
				) : null}
				{props.flag ? (
					<Image
						className={props.iconClassName}
						height={20}
						width={20}
						src={props.flag}
					/>
				) : null}
				{props.text}
			</Button>
		</div>
	);
};

export default CustomButton;
